import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@material-ui/core";
export default function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 4,
        justifyContent:'center'
      }}
    >
     
      <hr width="70%" size="5" align="center" />
      <Typography align="center">
        website footers  website footers website footers
      </Typography>
    </Box>
  );
}
