import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (val) => {
    setOpen(val);
  };

  React.useEffect(() => {
    if (props.isopen) {
      handleClickOpen(true);
    } else {
      handleClickOpen(false);
    }
  }, [props.isopen]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-end"
              justify="flex-start"
              padding="10px"
            >
              <Button onClick={() => props.onclose()}>Back</Button>
            </Grid>
          </Grid>
        </Grid>

        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item elevation={0}>
                {" "}
                <h1>Privacy Policy & Terms of Service.</h1>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "15px", textAlign:'left' }}>
                Paragraphs are the building blocks of papers. Many students
                define paragraphs in terms of length: a paragraph is a group of
                at least five sentences, a paragraph is half a page long, etc.
                In reality, though, the unity and coherence of ideas among
                sentences is what constitutes a paragraph. A paragraph is
                defined as “a group of sentences or a single sentence that forms
                a unit” (Lunsford and Connors 116). Length and appearance do not
                determine whether a section in a paper is a paragraph. For
                instance, in some styles of writing, particularly journalistic
                styles, a paragraph can be just one sentence long. Ultimately, a
                paragraph is a sentence or group of sentences that support one
                main idea. In this handout, we will refer to this as the
                “controlling idea,” because it controls what happens in the rest
                of the paragraph
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
}
