import React from "react";
import "./BottomNavBar.css";
import { Toolbar, Typography } from "@material-ui/core";

function BottomNavBar() {
  return (
    <div className="bottom-nav-bar-container">
      <Toolbar>
        <Typography
          style={{ flexGrow: 1, textAlign: "center", color: "white" }}
        >
          2022
        </Typography>
      </Toolbar>
    </div>
  );
}

export default BottomNavBar;
