import React from "react";
import {  useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@material-ui/core";
export default function BackMove() {
  let history = useNavigate();
  const backbuttonHandler = () => {
    console.log("hereee/////");
    history("/home");
  };
  return (
    <Grid item xs={8} marginTop={8} p={2}>
      <IconButton onClick={backbuttonHandler}>
        <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
        <span style={{ fontSize: "15px" }}>BACK</span>
      </IconButton>
    </Grid>
  );
}
