import React from "react";
import Typography from "@mui/material/Typography";
import QRCode from "qrcode.react";
import Box from "@mui/material/Box";
import { useStyles } from "../../common/utility/home.js";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CardWrapper from "../../common/utility/Card";
import Grid from "@mui/material/Grid";
import { BoxImageWrapper } from "../../common/utility/Box";
import demo from "../../img/downloadData.jpg";
import Collapse from "@mui/material/Collapse";
import argos from "../../img/argos.png";
import lewis from "../../img/lewis.png";
import curys from "../../img/curys.jpeg";
import Button from "@mui/material/Button";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#D3D3D3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function WishListData() {
  const [expanded, setExpanded] = React.useState(false);
  const [expandval, setExpandValue] = React.useState();

  const handleExpandClick = (val) => {
    setExpandValue(val);
    setExpanded(!expanded);
  };
  const classes = useStyles();
  return (
    <>
      <Box className={classes.boxwrapperwishlist}>
        <Box
          sx={{
            width: 1,
            backgroundColor: "#D3D3D3",
          }}
        >
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
            <Box gridColumn="span 8">
              <Item elevation={0}>
                <Typography
                  variant="h4"
                  textAlign="center"
                  style={{ flexDirection: "column" }}
                >
                  WISHLIST
                </Typography>
                <Typography
                  variant="h7"
                  textAlign="center"
                  style={{ flexDirection: "column" }}
                >
                  Show this QR code at the Creative studio to gain entry.
                </Typography>
              </Item>
            </Box>
            <Box gridColumn="span 4">
              <Item elevation={0}>
                {" "}
                <QRCode
                  id="qr-gen"
                  value={"qrValue"}
                  size={70}
                  level={"Q"}
                  bgColor={"#D3D3D3"}
                />
              </Item>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.boxwrapper}>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} marginTop={2}>
              <img
                src={demo}
                alt="Norway"
                className="card-images"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>

            <Grid item xs={12} onClick={() => handleExpandClick(2)}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  <b>I WANT THIS</b>
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>

          <Collapse
            in={expandval === 2 && expanded}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <BoxImageWrapper>
                  <Button variant="contained" style={{ width: "100%" }}>
                    <b>BUT ONLINE AT HP.COM</b>
                  </Button>
                </BoxImageWrapper>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  textAlign="center"
                >
                  PICK IT UP TODAY AT
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BoxImageWrapper>
                <Box>
                  <Grid container rowSpacing={0}>
                    <Grid item xs={6}>
                      <Item elevation={0}>
                        {" "}
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 300,
                            maxHeight: { xs: 150, md: 150 },
                            maxWidth: { xs: 150, md: 150 },
                          }}
                          alt="The house from the offer."
                          src={argos}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item elevation={0}>
                        {" "}
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 200,
                            maxHeight: { xs: 150, md: 150 },
                            maxWidth: { xs: 150, md: 150 },
                          }}
                          alt="The house from the offer."
                          src={lewis}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item elevation={0}>
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 200,
                            maxHeight: { xs: 150, md: 150 },
                            maxWidth: { xs: 150, md: 150 },
                          }}
                          alt="The house from the offer."
                          src={curys}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </BoxImageWrapper>
            </Grid>
          </Collapse>
        </CardWrapper>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} marginTop={2}>
              <img
                src={demo}
                alt="Norway"
                className="card-images"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>

            <Grid item xs={12} onClick={() => handleExpandClick(1)}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  I WANT THIS
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>

          <Collapse
            in={expandval === 1 && expanded}
            timeout="auto"
            unmountOnExit
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <BoxImageWrapper>
                  <Button variant="contained" style={{ width: "100%" }}>
                    <b>BUT ONLINE AT HP.COM</b>
                  </Button>
                </BoxImageWrapper>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  textAlign="center"
                >
                  PICK IT UP TODAY AT
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BoxImageWrapper>
                <Box>
                  <Grid container rowSpacing={0}>
                    <Grid item xs={6}>
                      <Item elevation={0}>
                        {" "}
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 300,
                            maxHeight: { xs: 150, md: 150 },
                            maxWidth: { xs: 150, md: 150 },
                          }}
                          alt="The house from the offer."
                          src={argos}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item elevation={0}>
                        {" "}
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 200,
                            maxHeight: { xs: 150, md: 150 },
                            maxWidth: { xs: 150, md: 150 },
                          }}
                          alt="The house from the offer."
                          src={lewis}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item elevation={0}>
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 200,
                            maxHeight: { xs: 150, md: 150 },
                            maxWidth: { xs: 150, md: 150 },
                          }}
                          alt="The house from the offer."
                          src={curys}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </BoxImageWrapper>
            </Grid>
          </Collapse>
        </CardWrapper>
      </Box>
    </>
  );
}
