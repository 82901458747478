import React from "react";
import BackMove from "../../common/components/BackMove";
import "./style.css";
import EmptyWishList from "./EmptyWishList";
import WishListData from "./WishListData";

export default function WishList() {
  return (
    <>
      <BackMove />

     {/* <EmptyWishList/> */}
     <WishListData/> 
    </>
  );
}
