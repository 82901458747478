import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useStyles } from "../../common/utility/home.js";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#D3D3D3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function EmptyWishList() {
  const classes = useStyles();
  return (
    <Box className={classes.rangewrapper}>
      <Box
        sx={{
          width: 1,
          backgroundColor: "#D3D3D3",
        }}
      >
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
          <Box gridColumn="span 12">
            <Item elevation={0}>
              <Typography
                variant="h4"
                textAlign="center"
                style={{ flexDirection: "column" }}
              >
                WISHLIST
              </Typography>
              <Typography
                variant="h7"
                textAlign="center"
                style={{ flexDirection: "column" }}
              >
                Add items to your wishlist togain access to the Creative
                Studio.great giveaways and more!
              </Typography>
            </Item>
          </Box>
         
        </Box>
      </Box>

      <Container maxWidth="sm" sx={{ height: "500px" }}></Container>
    </Box>
  );
}
