import React from "react";
import CardWrapper from "../../common/utility/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import HpLogo from "../../img/Logo.jpeg";
import Omen from "../../img/omENLogo.jpg";
import Button from "@mui/material/Button";
import {  Grid } from "@material-ui/core";
import sample from "../../img/sample1.png";
import { useStyles } from "../../common/utility/home.js";
import QRCode from "qrcode.react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Bag from "../../img/bag.jpeg";
import game from "../../img/game1.jpeg";
import gameFra from "../../img/fracry.jpeg";
import Laptop from "../../img/Laptop.png";
import { useNavigate } from "react-router-dom";
import {
  BoxWrapper,
  BoxContainer,
  BoxContainerImage,
  BoxMain,
  BoxWrapperCard,
  BoxWrapperText,
  BoxImageWrapper,
} from "../../common/utility/Box";
export default function HomePage(props) {
  let history = useNavigate();
  const GoToRangeHandler = () => {
    history("/range");
  };

  // const myRef = useRef(null);
  // const executeScroll = () => myRef.current.scrollIntoView(false);
  // // run this function from an event handler or an effect to execute scroll

  // useEffect(() => {
  //   if (myRef) executeScroll();
  //   console.log("loading....");
  // }, []);

  // useEffect(() => {
  //   if (myRef) executeScroll();
  //   console.log("....loading....");
  // }, [myRef]);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.boxwrapper}>
        <CardWrapper>
          <BoxWrapper>
            <Avatar alt="Logo" src={HpLogo} />
          </BoxWrapper>
          <BoxContainer>
            <Grid>
              <Typography component="h1" variant="h5">
                WHATS GOING ON.
              </Typography>
            </Grid>
          </BoxContainer>
          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign="center"
            >
              We're brining you the best of HPs,AMD powered desktop and laptops,
              Please feel to browse the experience. Our staff will be more than
              happy to guide you round and answer any questions you may have.
            </Typography>
          </CardContent>
          <BoxContainerImage>
            <ImageList cols={1}>
              <ImageListItem key={1}>
                <img
                  src={`${sample}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${sample}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={"itemData.title"}
                  loading="lazy"
                />
              </ImageListItem>
            </ImageList>
          </BoxContainerImage>
        </CardWrapper>
        <CardWrapper>
          <BoxWrapper>
            <Typography component="h3" variant="h7" color="white" p={1}>
              ADD TO WISHLIST
            </Typography>
          </BoxWrapper>
          <BoxContainer>
            <Grid>
              {" "}
              <Typography component="h1" variant="h5">
                SCAN FOR MORE.
              </Typography>
            </Grid>
          </BoxContainer>

          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign="center"
            >
              Along the way you'll notice QR codes.If you'd like to find out a
              littile more about the product just scan,Add products to your
              personal wishlist and you'll qualifiy to design and take home you
              free personalised tote bag along with entered into a prize draw.
            </Typography>
          </CardContent>
          <BoxMain>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <img
                  src={`${sample}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${sample}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={"itemData.title"}
                  loading="lazy"
                  width={"100%"}
                />
              </Grid>
              <Grid item xs={6}>
                <QRCode
                  id="qr-gen"
                  value={"qrValue"}
                  width={"100%"}
                  level={"Q"}
                  bgColor={"#D3D3D3"}
                />
              </Grid>
            </Grid>
          </BoxMain>
        </CardWrapper>
        {/* 3rdcard */}
        <CardWrapper>
          <BoxWrapperCard>
            <Typography component="h3" variant="h7" color="white" p={1}>
              LIFT YOUR PLAY UP.WAY UP
            </Typography>
          </BoxWrapperCard>
          <BoxContainerImage>
            <ImageList cols={1}>
              <ImageListItem key={1}>
                <img
                  src={`${Omen}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${Omen}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={"itemData.title"}
                  loading="lazy"
                />
              </ImageListItem>
            </ImageList>
          </BoxContainerImage>
          <BoxContainer>
            <Grid>
              {" "}
              <Typography component="h1" variant="h5">
                CHECKOUT THE RANGE.
              </Typography>
            </Grid>
          </BoxContainer>
          <CardContent>
            <BoxWrapperText>
              <Typography variant="body2" color="text.secondary">
                This impressive paella is a perfect party dish and a fun meal
              </Typography>
            </BoxWrapperText>
          </CardContent>
        </CardWrapper>
        <CardWrapper>
          <BoxWrapper>
            <Typography component="h3" variant="h7" color="white" p={1}>
              CREATIVE STUDIO
            </Typography>
          </BoxWrapper>
          <BoxContainer>
            <Grid>
              <Typography component="h1" variant="h5">
                FREE TOTE BAG!
              </Typography>
            </Grid>
          </BoxContainer>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              This impressive paella is a perfect party dish and a fun meal to
              cook together with your guests. Add 1 cup of frozen peas along
              with the mussels, if you like.
            </Typography>
          </CardContent>
          <BoxContainerImage>
            <ImageList cols={1}>
              <ImageListItem key={1}>
                <img
                  src={`${Bag}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${Bag}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={"itemData.title"}
                  loading="lazy"
                />
              </ImageListItem>
            </ImageList>
          </BoxContainerImage>
        </CardWrapper>

        <CardWrapper>
          <BoxWrapperCard>
            <Typography component="h3" variant="h7" color="white" p={1}>
              START GAMING.
            </Typography>
          </BoxWrapperCard>
          <BoxContainer>
            <Grid>
              <Typography component="h1" variant="h6">
                EMBARK ON YOUR GAMEING
              </Typography>
            </Grid>
            <Grid>
              <Typography component="h1" variant="h6">
                JOURNEY
              </Typography>
            </Grid>
          </BoxContainer>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              This impressive paella is a perfect party dish and a fun meal to
              cook together with your guests. Add 1 cup of frozen peas along
              with the mussels, if you like.
            </Typography>
          </CardContent>
          <BoxContainerImage>
            <img
              src={`${game}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${game}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={"item.title"}
              loading="lazy"
              style={{ width: "60%", height: "300px" }}
            />
          </BoxContainerImage>
          <BoxContainerImage>
            <img
              src={`${gameFra}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${gameFra}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={"item.title"}
              loading="lazy"
              style={{ width: "60%", height: "300px" }}
            />
          </BoxContainerImage>
        </CardWrapper>
        <CardWrapper>
          <BoxWrapperCard>
            <Typography component="h3" variant="h7" color="white" p={1}>
              IN A HURRY?
            </Typography>
          </BoxWrapperCard>

          <BoxContainerImage>
            <img
              src={`${Laptop}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${Laptop}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={"itemData.title"}
              loading="lazy"
              style={{ width: "100%", height: "100%" }}
            />
          </BoxContainerImage>

          <BoxImageWrapper>
            <Button
              variant="contained"
              style={{ width: "100%" }}
              onClick={GoToRangeHandler}
            >
              BROWSE PRODUCTS HERE
            </Button>
          </BoxImageWrapper>
        </CardWrapper>
      </Box>
    </>
  );
}
