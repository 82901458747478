import React, { useEffect } from "react";
import BackMove from "../../common/components/BackMove";
import Container from "@mui/material/Container";
import {
  BoxContainerImage,
  BoxWrapperCard,
  BoxContainer,
  BoxImageWrapper,
} from "../../common/utility/Box";
import CardWrapper from "../../common/utility/Card";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Logo from "../../img/Range.png";
import Typography from "@mui/material/Typography";
import laptopomen from "../../img/sample1.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { useStyles } from "../../common/utility/home.js";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
export default function Range() {
  let history = useNavigate();
  const classes = useStyles();
  const GoToRangeHandler = (val) => {
    history(val);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BackMove />
      <Container component="main" maxWidth="xl">
        <Box className={classes.rangewrapper}>
          <BoxContainerImage>
            <ImageList cols={1}>
              <ImageListItem key={1}>
                <img
                  src={`${Logo}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${Logo}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={"itemData.title"}
                  loading="lazy"
                />
              </ImageListItem>
            </ImageList>
          </BoxContainerImage>
          <CardWrapper>
            <BoxWrapperCard>
              <Typography component="h3" variant="h7" color="white" p={1}>
                LIFT YOUR PLAY UP. WAY UP
              </Typography>
            </BoxWrapperCard>

            <BoxContainerImage>
              <ImageList cols={1}>
                <ImageListItem key={1}>
                  <img
                    src={`${laptopomen}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${laptopomen}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={"itemData.title"}
                    loading="lazy"
                    style={{ width: 400, height: 250 }}
                  />
                </ImageListItem>
              </ImageList>
            </BoxContainerImage>
            <BoxContainer>
              <Grid>
                <Typography component="h6" variant="h6">
                  OMEN
                </Typography>
              </Grid>
            </BoxContainer>
            <CardContent>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                Our most powerful gaming pcs raise the bar on performace with
                advanced cooling systems, fast refresh rates and awesome
                graphics.
              </Typography>
            </CardContent>

            <BoxImageWrapper>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => GoToRangeHandler("/omen-range")}
              >
                BROWSE THE RANGE
              </Button>
            </BoxImageWrapper>
          </CardWrapper>
          <CardWrapper>
            <BoxWrapperCard>
              <Typography component="h3" variant="h7" color="white" p={1}>
                START GAMING
              </Typography>
            </BoxWrapperCard>

            <BoxContainerImage>
              <ImageList cols={1}>
                <ImageListItem key={1}>
                  <img
                    src={`${laptopomen}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${laptopomen}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={"itemData.title"}
                    loading="lazy"
                    style={{ width: 400, height: 250 }}
                  />
                </ImageListItem>
              </ImageList>
            </BoxContainerImage>
            <BoxContainer>
              <Grid>
                <Typography component="h6" variant="h6">
                  VICTUS
                </Typography>
              </Grid>
            </BoxContainer>
            <CardContent>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                Perfect for everyday gamers.Victus was created to introduce
                gamers to an accessible but elevated gaming experice.
              </Typography>
            </CardContent>
            <BoxImageWrapper>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => GoToRangeHandler("/victus-range")}
              >
                BROWSE THE RANGE
              </Button>
            </BoxImageWrapper>
          </CardWrapper>
        </Box>
      </Container>
    </>
  );
}
