import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import HPLogo from "../../img/HP_logo.png";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { BootstrapInput } from "../../common/utility/InputBox";
import FullScreenDialog from "./Policy";
const theme = createTheme();

export default function SignIn() {
  let history = useNavigate();
  const [bottomDrawer, setBottomDrawer] = React.useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const onClose = () => {
    setBottomDrawer(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" mb={12}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={HPLogo}
            sx={{ width: 56, height: 56, m: 1 }}
          ></Avatar>

          <Typography component="h6" variant="h6" align="center">
            Welcome to the HP & AMD expericence.
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            <Typography style={{ fontSize: "15px" }}>
              {" "}
              Register below to create your wish-list,find out more about the
              experience,receive giveaways and a chance to <br />
              win fantastic prizes!
            </Typography>
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      FullName
                    </InputLabel>
                    <BootstrapInput id="bootstrap-input" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Email
                    </InputLabel>
                    <BootstrapInput id="bootstrap-input" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Telephone(Optional)
                    </InputLabel>
                    <BootstrapInput id="bootstrap-input" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label={
                        <Typography variant="h9" color="text.secondary">
                          Signup for email updates.
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox value="remember" color="primary" checked />
                      }
                      label={
                        <Typography variant="h9" color="text.secondary">
                          Over 18
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, p: 2 }}
              onClick={() => history("/home")}
            >
              <b>Register & CONTINUE</b>
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button onClick={() => setBottomDrawer(true)}>
                <Typography variant="body2" color="text.secondary">
                  By continuing you agree to our <br />
                  <span style={{ color: "#68BBE3" }}>
                    Terms of Service and Privacy Policy
                  </span>
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <FullScreenDialog isopen={bottomDrawer} onclose={onClose} />
      </Container>
    </ThemeProvider>
  );
}
