import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles({
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 2,
    backgroundColor: "black",
  },
  action: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "Blue",
    color: "white",
    marginLeft: 22,
    marginRight: 22,
    marginBottom: 22,
    padding: 12,
  },

  actionimage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    marginLeft: 22,
    marginRight: 22,
    marginBottom: 22,
    padding: 12,
  },
  boxcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 16,
  },
  boximage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 22,
  },
  boxtop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 2,
  },
  boxMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop:32
  },

  boxImageConatiner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 16,
    
  },
  boxImageConatinerBlack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor:'black',

    
  },
  boxCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 2,
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  boxCardText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 2,
  },
  boxCardHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 2,
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
  },

  boxCardHeaderBlack: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 2,
    background: "#000000",
    color: "white",
  },
});
const BoxWrapper = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.box}>{children}</Box>;
};
const BoxActionWrapper = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.action}>{children}</Box>;
};

const BoxImageWrapper = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.actionimage}>{children}</Box>;
};
const BoxContainer = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxcontainer}>{children}</Box>;
};
const BoxContainerImage = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boximage}>{children}</Box>;
};
const BoxContainerTop = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxtop}>{children}</Box>;
};

const BoxMain = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxMain}>{children}</Box>;
};
const BoxMainImage = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxImageConatiner}>{children}</Box>;
};
const BoxMainImageBlack = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxImageConatinerBlack}>{children}</Box>;
};
const BoxWrapperCard = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxCard}>{children}</Box>;
};

const BoxWrapperText = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxCardText}>{children}</Box>;
};

const BoxWrapperHead = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxCardHeader}>{children}</Box>;
};
const BoxWrapperHeadBlack = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.boxCardHeaderBlack}>{children}</Box>;
};
export {
  BoxWrapper,
  BoxContainer,
  BoxContainerImage,
  BoxMain,
  BoxWrapperCard,
  BoxWrapperText,
  BoxActionWrapper,
  BoxWrapperHead,
  BoxMainImage,
  BoxWrapperHeadBlack,
  BoxMainImageBlack,
  BoxContainerTop,
  BoxImageWrapper
};
