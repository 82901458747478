import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Divider, Grid, Box } from "@material-ui/core";
import iconWishList from "../../img/wish-list.png";
import iconuser from "../../img/user.png";
import search from "../../img/search.png";
import HPLogo from "../../img/HP_logo.png";
import "./topbar.css";
import { useNavigate } from "react-router-dom";
function TopNavBar(props) {
  const history = useNavigate();
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ bgcolor: "white", color: "black" }}
        elevation={0}
        component="nav"
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props.ontoggler}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <IconButton onClick={() => history("/home")}>
              <img src={HPLogo} className="img-Logo" alt="logo" />{" "}
            </IconButton>
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid container item spacing={3}>
                <Grid item xs={4}>
                  <IconButton>
                    {" "}
                    <img src={search} className="img-icon" alt="search" />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <IconButton onClick={() => history("/profile")}>
                    <img src={iconuser} className="img-icon" alt="user" />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <IconButton onClick={() => history("/wishlist")}>
                    <img
                      src={iconWishList}
                      className="img-icon"
                      alt="wishlist"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>
    </>
    // </div>
  );
}

export default TopNavBar;
