import React from "react";
import BackMove from "../../common/components/BackMove";
import desktop1 from "../../img/download.jpeg";
import Box from "@mui/material/Box";
import { Typography } from "@material-ui/core";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "./style.css";
import { BoxActionWrapper } from "../../common/utility/Box";
export default function SeeMore() {
  return (
    <>
      <BackMove />
      <Container
        component="main"
        maxWidth="xl"
        style={{ height: "fit-content" }}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={3}>
            <img src={desktop1} alt="Norway" style={{ width: "100%" }} />
          </Grid>
        </Grid>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              OMEN BY hp 16-c0006na Gaming Laptop with Ryzen™ 7 and RX 6600M
            </Grid>
            <Grid item xs={12}>
              <p className="details">SPECIFICATION</p>
              <p className="specific">
                <Typography>
                  {"\u2022"} &nbsp;Windows 11 Home AMD Ryzen™ 7 5800H 16 GB RAM
                  512 GB SSD 40.9 cm (16.1 ") &nbsp; {"\u2022"}&nbsp;QHD (2560 x
                  1440), 165 Hz&nbsp; {"\u2022"}&nbsp;3 ms response time, 300
                  nits&nbsp; {"\u2022"}&nbsp;100% sRGB AMD Radeon™ RX 6600M
                </Typography>
              </p>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" textAlign="center">
                Go beyond with the OMEN by HP 16.1 inch Gaming Laptop. Powered
                by an AMD processor[1] and mighty graphics, play at your best
                from anywhere. Expand your gaming world with sharper and
                smoother visual experiences courtesy of a high resolution and a
                fast refresh rate display. Stay cool under pressure with OMEN
                Tempest Cooling Technology[2] that prevents overheating even
                when your game heats up.
              </Typography>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Typography variant="h6">
                  <span style={{ color: "blue" }}>SEE MORE AT HP.COM</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <div className="bottom-nav-bar-container-1">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  FROM ₹ 1,399.00
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                  <BoxActionWrapper>
                    <b>ADD TO WISHLIST</b>
                  </BoxActionWrapper>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      </Container>
    </>
  );
}
