import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css'
import HomePage from "./components/Home";
import Profile from './components/profile/Profile'
import Registration from "./components/Registration/Registration";
import ScrollToTop from "./components/ScrollTop";
import SidebarLayout from "./common/SidebarLayout";
import BottomNavBar from "./components/BottomNavBar/BottomNavBar";
import Footer from "./components/Layout/Footer";
import Range from "./components/Range/Range";
import OmenLaptop from "./components/Range/OmenLaptop";
import Victus from "./components/Range/Victus";
import SeeMore from "./components/Range/SeeMore";
import WishList from "./components/wishList";
const App = () => {
  return (
    <Router>
     <ScrollToTop>
        < >
          <Routes>
            <Route element={<SidebarLayout />}>
            <Route index path="/home" element={<HomePage/>} />
              <Route  path="/Profile" element={<Profile/>} />
              <Route exact path="/omen-range" element={<OmenLaptop/>} />
            <Route exact path="/range" element={<Range/>} />
            <Route exact path="/victus-range" element={<Victus/>} />
            <Route exact path="/details" element={<SeeMore/>} />
            <Route exact path="/wishlist" element={<WishList/>} />
            </Route>

            <Route exact path="/" element={<Registration />} />
           
          </Routes>
          <BottomNavBar/>
          <Footer/>
        </>
      </ScrollToTop>
    </Router>
  );
};

export default App;
