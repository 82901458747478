import React from "react";
import Grid from "@mui/material/Grid";
import BackMove from "../../common/components/BackMove";
import {
  BoxMainImageBlack,
  BoxWrapperHeadBlack,
  BoxImageWrapper,
} from "../../common/utility/Box";
import Button from "@mui/material/Button";
import TextLogo from "../../img/victus2.jpg";
import demo from "../../img/victusdesk.jpeg";
import desktop1 from "../../img/victus1.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import victus4 from "../../img/victus4.jpg";
import { useStyles } from "../../common/utility/home";
import CardWrapper from "../../common/utility/Card";
export default function OmenLaptop() {
  const classes = useStyles();
  return (
    <>
      <BackMove />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          &nbsp; &nbsp; VICTUS Laptop
        </Grid>
      </Grid>

      <BoxWrapperHeadBlack>
        <Grid item xs={12} marginTop={1} marginBottom={1}>
          &nbsp; &nbsp; DON'T SETTILE WHEN IT COMES TO PLAY
        </Grid>
      </BoxWrapperHeadBlack>
      <BoxMainImageBlack>
        <img src={desktop1} alt="Norway" />
      </BoxMainImageBlack>
      <Box className={classes.boxwrapper}>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>
            <Grid item xs={4} marginTop={2}>
              <img src={TextLogo} alt="Norway" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>

        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} marginTop={2}>
              <img src={TextLogo} alt="Norway" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>
            <Grid item xs={4} marginTop={2}>
              <img src={TextLogo} alt="Norway" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12}>
            <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          &nbsp; &nbsp; VICTUS Desktop
        </Grid>
      </Grid>

      <BoxWrapperHeadBlack>
        <Grid item xs={12} marginTop={1} marginBottom={1}>
          &nbsp; &nbsp; WORK HARD AND PLAY EASY.
        </Grid>
      </BoxWrapperHeadBlack>

      <BoxMainImageBlack>
        <img src={demo} alt="Norway" style={{ width: "30%" }} />
      </BoxMainImageBlack>
      <Box className={classes.boxwrapper}>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} marginTop={2}>
              <img src={victus4} alt="Norway" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>
      </Box>
    </>
  );
}
