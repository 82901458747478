import React from "react";
import Grid from "@mui/material/Grid";
import BackMove from "../../common/components/BackMove";
import {
  BoxWrapperHead,
  BoxMainImage,
  BoxImageWrapper,
} from "../../common/utility/Box";
import TextLogo from "./mm.jpeg";
import demo from "../../img/downloadData.jpg";
import desktop1 from "../../img/download.jpeg";
import desk from "../../img/desktop1.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import CardWrapper from "../../common/utility/Card";
import { useStyles } from "../../common/utility/home";
import Button from "@mui/material/Button";
export default function OmenLaptop() {
  let history = useNavigate();
  const classes = useStyles();
  const handleExpandClick = () => {
    history("/details");
  };
  return (
    <>
      <BackMove />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          &nbsp;&nbsp; &nbsp; OMEN Laptop
        </Grid>
      </Grid>

      <BoxWrapperHead>
        <Grid item xs={12} marginTop={1} marginBottom={1}>
        &nbsp;&nbsp; &nbsp;  LOOK AS GOOD AS YOUR GAME
        </Grid>
      </BoxWrapperHead>

      <BoxMainImage>
        <img
          src={desktop1}
          alt="Norway"
          style={{ width: "20%", height: "220px" }}
        />
      </BoxMainImage>
      <Box className={classes.boxwrapper}>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>
            <Grid item xs={4} marginTop={2}>
              <img
                src={demo}
                alt="Norway"
                className="card-images"
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} onClick={handleExpandClick}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} marginTop={2}>
              <img
                src={demo}
                alt="Norway"
                className="card-images"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>
            <Grid item xs={4} marginTop={2}>
              <img
                src={demo}
                alt="Norway"
                className="card-images"
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={8}>
        &nbsp;&nbsp; &nbsp; OMEN Desktop
        </Grid>
      </Grid>

      <BoxWrapperHead>
        <Grid item xs={12} marginTop={1} marginBottom={1}>
        &nbsp;&nbsp; &nbsp;  DESIGNED FOR THE GAMER
        </Grid>
      </BoxWrapperHead>
      <BoxMainImage>
        <img src={TextLogo} alt="Norway" style={{ width: "60%" }} />
      </BoxMainImage>

      <Box className={classes.boxwrapper}>
        <CardWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4} marginTop={2}>
              <img src={desk} alt="Norway" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={8} marginTop={2}>
              {" "}
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
              >
                OMEN by HP 16-c000na Laptop-AMD Radeon™ RX 6600M
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BoxImageWrapper>
                <Button variant="contained" style={{ width: "100%" }}>
                  SEE MORE
                </Button>
              </BoxImageWrapper>
            </Grid>
          </Grid>
        </CardWrapper>
      </Box>
    </>
  );
}
