import { Outlet } from "react-router-dom";
import TemporaryDrawer from "../components/Layout/Appbar";
const SidebarLayout = () => (
  <>
    <div>
      <TemporaryDrawer />
    </div>
    <Outlet />
  </>
);

export default SidebarLayout;
