import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { SidebarData } from "./SidebarData";
import { useNavigate } from "react-router-dom";
import "./app.css";
import TopNavBar from "./TopNavBar";
export default function TemporaryDrawer() {
  let history = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const menuItemClick = (path) => {
    if (path === "/email") {
      window.location = "mailto:yourmail@gmail.com";
    } else {
      setState({ ...state, ["left"]: false });
      history(path);
    }
  };
  return (
    <div>
      <>
        <TopNavBar ontoggler={toggleDrawer("left", true)} />

        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          PaperProps={{
            sx: {
              backgroundColor: "#cccccc",
            },
          }}
        >
          <div className="nav-data-container">
            <div className="nav-sub-container">
              <Button
                className="nav-strong-text"
                onClick={toggleDrawer("left", false)}
              >
                Close
              </Button>
            </div>
            <div className="nav-sub-container-para">
              <p>BROWSE</p>
            </div>
            {SidebarData.map((navMenu) => (
              <div className="nav-sub-container" key={navMenu.title}>
                <IconButton
                  onClick={() => menuItemClick(navMenu.path)}
                  size={"small"}
                >
                  {navMenu.title}
                </IconButton>
              </div>
            ))}
          </div>
        </Drawer>
      </>
    </div>
  );
}
