export const SidebarData = [
  {
    title: " Home",
    path: "/range",
    cName: "nav-text",
  },
  {
    title: " OMEN Range",
    path: "/omen-range",
    cName: "nav-text",
  },
  {
    title: "VICTUS Range",
    path: "/victus-range",
    cName: "nav-text",
  },
  {
    title: "Whishlist",
    path: "/wishlist",
    cName: "nav-text",
  },
  {
    title: " Your Details",
    path: "/profile",
    cName: "nav-text",
  },
  {
    title: "Emails",
    path: "/email",
    cName: "nav-text",
  },
];
