import * as React from "react";
import { Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {  useNavigate } from "react-router-dom";
import { BootstrapInput } from "../../common/utility/InputBox";
import BackMove from "../../common/components/BackMove";
export default function Profile() {
  let history = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };


  return (
    <>
      
      <BackMove/>
      <Container component="main" maxWidth="xs">
     
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
           
          }}
        >
          <Typography component="h1" variant="h5">
            Your Details
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 5 }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Full Name
                    </InputLabel>
                    <BootstrapInput id="bootstrap-input" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Email
                    </InputLabel>
                    <BootstrapInput id="bootstrap-input" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Telephone(Optional)
                    </InputLabel>
                    <BootstrapInput id="bootstrap-input" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label={
                        <Typography variant="h9" color="text.secondary">
                          Signup for email updates.
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox value="remember" color="primary" checked />
                      }
                      label={
                        <Typography variant="h9" color="text.secondary">
                          Over 18
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, p: 2 }}
                  onClick={() => history("/home")}
                >
                  <b>UPDATE</b>
                </Button>
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button>
                By continuing you agree to our <br />
                Terms of Service and Privacy Policy
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
