
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  boxwrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    marginTop: 80,
    gap: "0.5em",
    width: "95%",
    position: "relative",
    margin: "0 auto",
    // flexDirection: "column",
    // alignItems: "center",
    // marginTop: 80,
    ['@media screen and (max-width: 500px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: "repeat(1,1fr)"
    },
    ['@media screen and (max-width: 979px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: "repeat(1,1fr)"
    }
  },

  boxwrapperwishlist: {

    gridTemplateColumns: "repeat(2,1fr)",
    gap: "0.5em",
    width: "95%",
    position: "relative",
    margin: "0 auto",
    // flexDirection: "column",
    // alignItems: "center",
    // marginTop: 80,
    ['@media screen and (max-width: 500px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: "repeat(1,1fr)"
    },
    ['@media screen and (max-width: 979px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: "repeat(1,1fr)"
    }
  },
  rangewrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  
  },
  boxcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#000000",
  },

 

});

export { useStyles };
